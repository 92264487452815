body {
  background-color: #0f0606;
  color: #fff;
}

html {
  font-family: "Poppins", sans-serif;
}

a {
  cursor: pointer;
}

.App {
  overflow: hidden;
}

.font-mont {
  font-family: "Montserrat", sans-serif;
}

.text-secondary {
  color: #f5f5f5;
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: -0.3px;
  -webkit-text-stroke-width: 0px;
  stroke-width: 0px;
  -webkit-text-stroke-color: #ffffff;
  stroke: #ffffff;
}

.text-primary {
  color: #1e8556;
  font-family: "Poppins", Sans-serif;
  font-size: 160px;
  font-weight: 900;
  -webkit-text-stroke-width: 3px;
  stroke-width: 3px;
  -webkit-text-stroke-color: #fffdfd;
  stroke: #fffdfd;
}

.text-primary-3 {
  color: #1e8556;
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #ece6e6;
  stroke: #ece6e6;
}

.text-primary-2 {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 26px;
  font-weight: 700;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #1e8556;
  stroke: #1e8556;
  text-shadow: -2px 0px 13px
    rgba(32.01470788043479, 213.32569769298658, 253.49999999999997, 0.3);
}

.btn-primary {
  letter-spacing: 4.3px;
  text-shadow: 0px 0px 11px rgba(0, 0, 0, 0.3);
  fill: #000000f5;
}

.bg-center-center {
  background-position: center center;
}

.text-tokenomics {
  color: #1b1914a8;
  font-family: "Poppins", Sans-serif;
  font-size: 65px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #1e8556;
  stroke: #1e8556;
  text-shadow: -2px 0px 13px
    rgba(32.01470788043479, 213.32569769298658, 253.49999999999997, 0.3);
}

.text-tokenomics-2 {
  color: #ffffff00;
  font-family: "Poppins", Sans-serif;
  font-size: 55px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #1e8556;
  stroke: #1e8556;
}

.text-tokenomics-3 {
  color: #4d5243;
  font-family: "Poppins", Sans-serif;
  font-size: 50px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #1e8556;
  stroke: #1e8556;
}

.text-tokenomics-4 {
  color: #1b1914a8;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #1e8556;
  stroke: #1e8556;
  text-shadow: -2px 0px 13px
    rgba(32.01470788043479, 213.32569769298658, 253.49999999999997, 0.3);
}

.text-tokenomics-5 {
  color: #ffffff00;
  font-family: "Poppins", Sans-serif;
  font-size: 22px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  stroke-width: 1px;
  -webkit-text-stroke-color: #1e8556;
  stroke: #1e8556;
}

@media screen and (max-width: 600px) {
  .text-primary {
    font-size: 50px;
  }
  .text-secondary {
    font-size: 20px;
    line-height: 40px;
  }
  .text-tokenomics {
    font-size: 45px;
  }
}
